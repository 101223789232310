import StateBoxComponent from '../components/StateBox/viewer/StateBox';
import StateBoxController from '../components/StateBox/viewer/StateBox.controller';


const StateBox = {
  component: StateBoxComponent,
  controller: StateBoxController
};


export const components = {
  ['StateBox']: StateBox
};

